import React from "react"
import Layout from "../../components/layout/layout"
import styled from 'styled-components';
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"

const TitleArea = styled.section`
    padding-top: 20px;
    padding-bottom: 0em;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    background-color: var(--neutral-300);
    margin-bottom: 30px;
`;

const Container = styled.div`
    width: 80%;
    max-width: 800px;
    margin: auto;
    p{
        margin-block-start: 0.8em;
        font-size: 18px;
    }
`;

const TitleText = styled.h1`
  font-size: 40px;
  text-align: center;
  text-transform:none;
  font-weight:800;
  color: var(--accent-turquoise-500);
  margin-block-end: 0em;
`;

const Headline = styled.div`
    padding: 45px 0 55px 0;
    color: white;
    margin: auto;
    max-width: 820px;
    font-size: 2.5rem;
    font-weight: 800;
    @media only screen and (min-width: 1000px) {
        font-size: 3rem; 
    }

`;

const GridContainer = styled.div`
    display: grid;
    @media only screen and (min-width: 800px) {
        grid-template-columns: 200px 1fr;
        grid-gap: 40px;
    }
`

const GridContainer2 = styled.div`
    display: grid;
    @media only screen and (min-width: 1400px) {
        grid-template-columns: 50% 1fr;
        grid-gap: 40px;
    }
    @media only screen and (max-width: 1399px) and (min-width: 900px) {
        grid-template-columns: 40% 1fr;
        grid-gap: 40px;
    }
`

const GridContainer3 = styled.div`
    display: grid;
    align-items:center;
    justify-content: center;
    align-self: center;
    @media only screen and (min-width: 800px) {
        grid-template-columns: 120px 1fr;
        grid-gap: 40px;
    }
`

const QuoteBox = styled.div`
    position: relative;
    padding: 20px 25px;
    background-color: white;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 30px;

    &::before {
    position: absolute;
    content: '';
    top: -25px;
    left: calc(50% - 25px);
    width: 0;
    height: 0; 
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 25px solid white;
    @media only screen and (min-width: 800px) {
        left: 75px;
    }
    }
`;

const Quote = styled.div`
padding-top:0;

@media only screen and (min-width: 800px) {
    padding-top:2rem; 
}
`;

const QuoteImg = styled.div`
    justify-self: center;
    margin-top: 2rem;
    width:100px;
    padding: 1rem 0;
@media only screen and (min-width: 800px) {
    margin-top: 0;
}

`;

const MainBox = styled.div`
width:80%;
margin: auto;
display: block;
height: auto;
overflow: auto;
padding-bottom: 30px;
`;

const TextArea = styled.div`
p{
        margin-block-start: 0em;
        font-size: 20px;
        font-weight: 600;
        color: white;
    }
    margin-bottom: 30px;
`;

const TextArea2 = styled.div`
p{
        margin-block-start: 0em;
        font-size: 16px;
    }
    margin-bottom: 30px;
    margin-top: 40px;
`;

const TextArea3 = styled.div`
p{
        margin-block-start: 0em;
        font-size: 16px;
    }
    margin-bottom: 30px;
    margin-top: -40px;
`;

const Box = styled.div`
    width: 80%;
    max-width: 1080px;
    margin: auto;
    padding: 0;
    background-color: #f59c32;
    box-shadow: var(--shadow-b);
    h2 {
    color: black;
    text-transform: none;
    width:80%;
    font-weight: 700;
    padding-top: 40px;
    }
    h5 {
        text-align: right;
        font-size: 16px;
        margin-top: 0;
    }

    p {
        font-size: 19px;
    }

    @media only screen and (min-width: 1000px) {
        padding: 0;
    }
`;

const Box2 = styled.div`
    width: 80%;
    max-width: 1080px;
    margin: auto;
    margin-top: 0;
    padding: 0;
    padding-top: 50px !important;
    padding-bottom: 20px !important;
    background-color: #FFFFFF;
    box-shadow: var(--shadow-b);
    h2, h3, h4 {
    margin-top:20px;
    }
    p {
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 30px;
    }

    @media only screen and (min-width: 1000px) {
        padding: 0;
    }
`;

const Box3 = styled.div`
    width: 80%;
    max-width: 1080px;
    margin: auto;
    margin-top: 0;
    padding: 0;
    padding-top: 40px !important;
    background-color: #efefef;
    box-shadow: var(--shadow-b);
    h2, h3, h4 {
    margin-top:0;
    padding-top: 20px;
    }
    p {
        font-size: 16px;
    }

    @media only screen and (min-width: 1000px) {
        padding: 0;
    }
`;

const ThreeColArea = styled.div`
    width: 80%;
    max-width: 1080px;
    margin: 0 auto;
    padding-top: 20px;
    display: block;
    justify-content: space-between;
    align-items: stretch;
    text-align: center;
    @media only screen and (min-width: 800px) {
        display: flex;
        width: 100%;
    }
`;

const ThreeColItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    margin-bottom: 0;
    img {
    width:100%;
    }
    h2 {
    align-self: center;
    padding: 0 10px;
    font-family: 'Open Sans', sans-serif;
    }
    @media only screen and (min-width: 800px) {
        width: 30%;
        h2 {
        font-size: calc(7px + 0.8vw);
        }
    }
    @media only screen and (min-width: 1600px) {
        h2 {
            font-size: 20px;
        }
    }
`;

const ThreeColCont = styled.section`
    width: 100%;
    margin: auto;
    padding-top: 1em;
    padding-bottom: 1em;

    @media only screen and (min-width: 1200px) {
        padding-top: 0em;
    }
`;

const TxtContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    p{
        font-size: 14px;
        text-align: left;
    }
`;



export default function DesigningForEnergy({ data }) {
  return (
    <Layout>
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Designing for Energy | Caplin Solar</title>
        <html lang="en"></html>
      </Helmet>
      <TitleArea>
        <Container>
            <TitleText>Designing for Energy</TitleText>
            <p>How do we build a fossil fuel free future?</p>
        </Container>
    </TitleArea>
        <Box>
            <Img fluid={data.brochureFrontImage.childImageSharp.fluid}  alt="A solar powered home"/>
            <MainBox>
                <Headline>Switching on to Solar Heat.</Headline>
                <GridContainer>
                    <div><QuoteBox>Around 90% of UK homes use fossil fuels for heating and hot water.</QuoteBox></div>
                    <TextArea>
                    <p>Heating is the UK’s biggest source of carbon emissions.
                    Decarbonising heat is key to achieving Net Zero.</p>
                    <p>It is essential that we prevent expansion of fossil fuel
                    heating and find renewable and sustainable solutions
                    to powering our homes. As the UK commits to Net Zero
                    CO2 emissions by 2050 we must work toward building
                    carbon free communities by shifting demand to high
                    efficiency and integrated energy solutions with net zero
                    emissions.</p>
                    </TextArea>
                </GridContainer>
            </MainBox>
        </Box>
        <Box2>
            <MainBox>
            <h4>Decarbonisation through electrification of heat.</h4>
            <p>The Future Homes Standard is expected to phase out
            fossil fuel heating installations from new-build homes by
            2025, with heat pumps being cited as a good way to heat a
            home effectively and efficiently using electricity.</p>
            <h4>On-site generation: a step away from grid-dependence.</h4>
            <p>CO2 emissions have been slashed from grid electricity as
            we increase renewable generation, but the carbon content
            still remains high, and relying on power from the grid for
            the energy-intensive process of heating our homes would
            be very expensive.</p>
            <p>As we make this shift from fossil fuels to the electrification
            of both heating and transport, we will inevitably add
            substantial load to the UK electrical grid. The limitations of
            the UK’s present electricity-network capacity and the costs
            and time associated with upgrades to infrastructure and
            storage could prove to be a serious obstacle.</p>
            <p>There has been a lot of focus on solar PV, but this
            technology converts only visible light into electricity. Most
            of the sun’s energy reaches the earth as heat or infra-red
            radiation. Hybrid PV-T panels capture both forms of energy.
            Seasonal imbalance remains a challenge for solar
            generation. A viable system requires energy storage.</p>
            </MainBox>
        </Box2>
        <Box3>
            <MainBox>
            <GridContainer2>
                <TextArea2><h4>Thermal storage:<br></br>
                A breakthrough in energy
                efficient housing.</h4>
                <p>Domestic thermal storage could be key to the
                long-term solution for the industry.</p>
                <p>Caplin Solar's Earth Energy Bank is an inter-seasonal
                thermal storage technology that
                can meet a home’s full annual hot water and
                heating requirements using just solar energy.
                The system stores heat in warmer months
                so that it can be used during colder times of
                the year.</p>
                </TextArea2>
                <Img fluid={data.systemImage.childImageSharp.fluid} alt="Caplin Solar system illustration" imgStyle={{objectFit:"contain"}} />
                </GridContainer2>
                <TextArea3>
                <ThreeColCont>
                <h4>The system consists of three core components:</h4>
                <ThreeColArea>
                    <ThreeColItem>
                            <Img fluid={data.solarPanel.childImageSharp.fluid} alt="PV-T panel" imgStyle={{objectFit:"contain"}} style={{overflow:"visible", paddingBottom:"10px"}} />
                        <TxtContainer>
                            <h2>Solar Collection</h2>
                            <p>Advanced solar energy capture, collecting both electrical and thermal energy, using hybrid PV-T panel arrays.</p>
                        </TxtContainer>
                    </ThreeColItem>
                    <ThreeColItem>
                            <Img fluid={data.earthEnergyBank.childImageSharp.fluid} alt="Earth Energy Bank" imgStyle={{objectFit:"contain"}}  style={{overflow:"visible", paddingBottom:"10px"}} />
                        <TxtContainer>
                            <h2>Thermal Storage</h2>
                            <p>Our patented Earth Energy Bank, which provides inter-seasonal energy storage within the footprint of the building.</p>
                        </TxtContainer>
                    </ThreeColItem>
                    <ThreeColItem>
                            <Img fluid={data.heatPump.childImageSharp.fluid} alt="Heat pump" imgStyle={{objectFit:"contain"}}  style={{overflow:"visible", paddingBottom:"10px"}} />
                        <TxtContainer>
                            <h2>Heat Pump</h2>
                            <p>Energy recovery using a conventional ground source heat pump, within a system that enhances its efficiency.</p>
                        </TxtContainer>
                    </ThreeColItem>
                </ThreeColArea>
                </ThreeColCont>
                </TextArea3>
            </MainBox>
        </Box3>
        <Box2>
            <MainBox>
                <h4>Integrated solar technology for new homes.</h4>
                <p>Hybrid photovoltaic-thermal (PVT) panels collect thermal energy and generate electricity. The heated fluid from the
                panels is pumped through the Earth Energy Bank, a matrix of shallow bores that sit within the footprint of the house.
                In winter the stored heat is extracted using a Ground Source Heat Pump. A sophisticated controller manages the flow of
                energy within the integrated system determining the best energy source to meet demand.</p>
                <h4>A system centred on enhancing efficiency.</h4>
                <p>Ground Source Heat Pumps are one of the most efficient
                ways to decarbonise heat. When combined with thermal
                storage the valuable opportunities for GSHP deployment
                are massively increased.</p>
                <p>The Earth Energy Bank is simple, quick to install,
                and inexpensive. It requires no additional land to be
                excavated, no costly equipment to be used. It is contained
                within the building’s foundations, making it ideal for use in
                multi-occupancy developments and housing estates.</p>
                <p>The combination of technologies within this system
                significantly boosts the standard operating performance
                of both the PVT panels and Ground Source Heat
                Pump. The higher source temperature enhances the
                performance of the GSHP and advanced cooling of the
                panels results in higher electrical output and less heat
                loss through thermal radiation.</p>
                <p>This multi-award winning system is scalable and
                economical. First installed in 2013 in a highly successful
                demonstrator, the Solar House, it is the product of 9 years
                of Research & Development and has been independently
                studied by world leading energy research institute, IESD.</p>
                <p>Earth Energy Banks are now powering houses nationwide
                and are currently being installed in a 47 home energy
                positive commuter village in Northants.</p>

            </MainBox>
        </Box2>
        <Box>
            <Img fluid={data.brochureBackImage.childImageSharp.fluid}  alt="A carbon free future"/>
            <MainBox>
                <GridContainer3>
                    <QuoteImg><Img fluid={data.quoteMark.childImageSharp.fluid}  alt="A quotation mark"/></QuoteImg>
                    <Quote>
                        <h2 style={{paddingTop:"0"}}>"Maximise on-site or nearby renewable energy production and self-consumption...</h2>
                        <p>to completely cover or exceed the total energy demand of each building
                        with the minimum exchange of energy with the grid.”</p>
                        <h5>- Roadmap to 2050</h5>
                    </Quote>
                </GridContainer3>
            </MainBox>
        </Box>
        <Box2>
        <div style={{paddingBottom:"45px", paddingTop:"20px"}}><Img fluid={data.logoMark.childImageSharp.fluid} alt="Caplin Solar logo" style={{width:"24%", margin:"auto"}}/></div>
        </Box2>
    </Layout>
    )
}

export const query = graphql`
  query {
    brochureFrontImage: file(relativePath: {eq: "images/Brochure_Front.png"}) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    brochureBackImage: file(relativePath: {eq: "images/Brochure_Back.png"}) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    systemImage: file(relativePath: {eq: "images/CS_System2.png"}) {
        childImageSharp {
        fluid(maxWidth: 790, quality: 72) {
            ...GatsbyImageSharpFluid_noBase64
        }
    }
  }
    solarPanel: file(relativePath: {eq: "images/solar-panel.png"}) {
        childImageSharp {
            fluid(maxWidth: 560, quality: 72) {
                ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    earthEnergyBank: file(relativePath: {eq: "images/earth-energy-bank.png"}) {
        childImageSharp {
            fluid(maxWidth: 560, quality: 72) {
                ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    heatPump: file(relativePath: {eq: "images/heat-pump.png"}) {
        childImageSharp {
            fluid(maxWidth: 560, quality: 72) {
                ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    logoMark: file(relativePath: {eq: "images/CS_LOGO-sq.png"}) {
        childImageSharp {
            fluid(maxWidth: 560, quality: 72) {
                ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    quoteMark: file(relativePath: {eq: "images/quote.png"}) {
        childImageSharp {
            fluid(maxWidth: 560, quality: 72) {
                ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    
}
`
